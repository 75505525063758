// Normalize Styles
//@import 'node_modules/normalize.css/normalize';

// Import Font-Faces
@import 'font-face';

// Import Skeleton SASS
@import 'skeleton';

// Import Modules
@import '../_modules/link/link';

$logo-size      : 320px;
$project-size   : 224px;
$icon-size      : 96px;
$main-bg        : #EEEEF2;
$transition-time: 0.3s;
$shadow-color   : rgba(0, 0, 0, 0.2);

// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    transition: $transition-time;
}

body {
    background: $main-bg;
    color: #252525;
}

h2 {
    font-size: 3.15rem;
}

.round {
    border-radius: 50%;
}

.hero {
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    h1 {
        font-weight: bold;
    }

    p {
        font-size: 1.85rem;
        margin-bottom: 1rem;
    }
}

.logo {
    text-align: center;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    background-color: #FFFFFF;
    box-shadow: 4px 4px 20px $shadow-color;
    max-height: $logo-size * 0.625;

    @media screen and ($bp-larger-than-phablet) {
        box-shadow: 6px 6px 28px $shadow-color;
        max-height: $logo-size * 0.8;
    }

    @media screen and ($bp-larger-than-desktop) {
        box-shadow: 8px 8px 32px $shadow-color;
        max-height: $logo-size;
    }
}

nav {
    background: $main-bg;

    ul {
        list-style: none;
    }

    .columns {
        text-align: center;
        font-size: 1.75rem;
        margin-left: 0;

        a {
            display: block;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            border-bottom: 3px solid $main-bg;
        }

        a:hover {
            border-bottom: 3px solid $link-hover-color;
        }
    }

    .columns:first-child {
        margin-left: 4%;
    }

    .columns:last-child {
        margin-right: 4%;
    }

    li {
        margin-bottom: 0;
    }

    @media screen and ($bp-larger-than-phablet) {
        position: sticky;
        top: 0;
    }
}

nav.scrolled {
    position: sticky;
    opacity: 0.8;
    z-index: 1000;

    a {
        font-size: 1.4rem;
    }
}

nav.scrolled:hover {
    opacity: 1.0;
}

.project {
    text-align: center;
    margin-bottom: 2rem;

    a {
        display: block;
        position: relative;

        img {

            border-radius: 50%;
            box-shadow: 6px 6px 18px $shadow-color;
            max-height: 160px;

            @media screen and ($bp-larger-than-phablet) {
                box-shadow: 4px 4px 8px $shadow-color;
                max-height: 128px;
            }

            @media screen and ($bp-larger-than-desktop) {
                box-shadow: 4px 4px 8px $shadow-color;
                max-height: 192px;
            }

            @media screen and ($bp-larger-than-desktophd) {
                box-shadow: 4px 4px 8px $shadow-color;
                max-height: 224px;
            }
        }

        .hover-link {
            position: absolute;
            top: 45%;
            left: 25%;
            font-size: 2rem;
            font-weight: bold;
            color: #252525;
            opacity: 0;
        }
    }

    a:hover > img {
        opacity: 0.6;
    }
}

/*
 * Arrow after expanded project
 * CSS from: https://tympanus.net/codrops/2013/03/19/thumbnail-grid-with-expanding-preview/
 */
a.expanded::after {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #808890;
    border-width: 15px;
    left: 50%;
    margin: -0.8rem 0 0 -15px;
}

a.expanded > img {
    opacity: 0.6;
}

.details {
    height: 0;
    overflow: hidden;
    background-color: #808890;
    color: #F0F0F0;
    box-shadow: 4px 4px 8px $shadow-color;
    border-radius: 10px;
    margin-bottom: 2rem;

    div > * {
        margin: 2rem;
    }

    a {
        color: #BBDDEE;
    }

    a:hover {
        color: #FFFFFF;
    }

    .close {
        position: relative;
        float: right;
        top: 0.75rem;
        right: 1.75rem;
        line-height: 3rem;
        font-size: 3rem;
        font-weight: bold;
        margin: 0;
        color: #F0F0F0;
    }
}

.details.expanded {
    height: 17rem;
}

.details::after {
    content: "";
    display: table;
    clear: both;
}

#contact
{
    ul {
        list-style: none;
        text-align: center;

        @media screen and ($bp-larger-than-phablet) {
            text-align: left;
        }
    }

    li {
        display: inline-block;
        margin-right: 2rem;

        img {
            display: block;
            background-color: #FFFFFF;
            border-radius: 50%;
            box-shadow: 3px 3px 6px $shadow-color;
            background-position: center;
            max-height: 48px;
            text-align: center;

            @media screen and ($bp-larger-than-phablet) {
                box-shadow: 4px 4px 8px $shadow-color;
                max-height: 64px;
            }

            @media screen and ($bp-larger-than-desktop) {
                box-shadow: 5px 5px 12px $shadow-color;
                max-height: 96px;
            }
        }
    }
}

footer {
    text-align: center;
    font-size: 1.1rem;
}

footer > p {
    margin-bottom: 1.5rem;
}

// Styling on warning for users on IE7 or below
.browsehappy {
    text-align: center;
    margin: 0;
    background: #fff8e2;
    color: #000;
    padding: 0.5em 0;
}
