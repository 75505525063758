// link styles
$link-color      :  #202C99;
$link-hover-color:  #5588EE;

// unvisited link
a:link, a:visited {
    color: $link-color;
    text-decoration: none;
    transition: 0.25s;
}

// mouse over link
a:active, a:hover {
    color: $link-hover-color;
}
